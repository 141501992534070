export default [
  // ---FIGMA_DESIGN---
  {
    // w/o Related Project
    portalGroup: 'advisory.public',
    path: '/job/:seo',
    component: { render: h => h('router-view') },
    redirect: { name: 'JobPostingRequirementDetails' },
    children:[
      {
        path: 'details',
        name: 'JobPostingRequirementDetails',
        component: () => import('../T1001JobPostingRequirement/PgJobPostingRequirement.vue'),
        // meta: { resetBreadcrumb: false }
      },
      {
        path: 'project',
        name: 'JobPostingRequirementRelatedProject',
        // component: () => import('./PgJobPostingRequirementRelatedProject.vue'),
      },
      {
        path: 'form/:id',
        name: 'JobPostingRequirementForm',
        component: () => import('../T1001JobPostingRequirement/PgTankPartnershipForm.vue')
      },
      {
        path: '*',
        redirect: { name: 'JobPostingRequirementDetails' }
      }
    ]
  },
  {
    portalGroup: 'advisory.public',
    path: '/product/:seo',
    component: { render: h => h('router-view') },
    redirect: { name: 'ProductDetails' },
    children:[
      {
        path: 'details',
        name: 'ProductDetails',
        component: () => import('../T2001Service/public/PgProductDetails.vue'),
        // meta: { resetBreadcrumb: false }
      },
      {
        path: 'checkout',
        name: 'ProductCheckout',
        component: () => import('../T2001Service/public/PgProductCheckout.vue'),
      },
      {
        path: '*',
        redirect: { name: 'ProductDetails' }
      }
    ]
  },
  // Fixed + hourly + Profit Sharing
  {
    portalGroup: 'advisory.public',
    path: '/service/:seo',
    component: { render: h => h('router-view') },
    redirect: { name: 'ServiceDetails' },
    children:[
      {
        path: 'details',
        name: 'ServiceDetails',
        component: () => import('../T2001Service/public/PgServiceDetails.vue'),
        // meta: { resetBreadcrumb: false }
      },
      {
        path: 'select',
        name: 'ServiceSelect',
        component: () => import('../T2001Service/public/PgServiceSelect.vue'),

      },
      {
        path: 'checkout',
        name: 'ServiceCheckout',
        component: () => import('../T2001Service/public/PgServiceCheckout.vue'),
      },
      {
        path: '*',
        redirect: { name: 'ServiceDetails' }
      }
    ]
  },
  {
    portalGroup: 'advisory.public',
    path: '/funding/:seo',
    component: { render: h => h('router-view') },
    redirect: { name: 'FundingDetails' },
    children:[
      {
        path: 'details',
        name: 'FundingDetails',
        component: () => import('../T3001Funding/public/PgFundingDetails.vue'),
        // meta: { resetBreadcrumb: false }
      },
      {
        path: '*',
        redirect: { name: 'FundingDetails' }
      }
    ]
  },
  {
    portalGroup: 'advisory.public',
    path: '/surveydata/:seo',
    component: { render: h => h('router-view') },
    redirect: { name: 'SurveyDataDetails' },
    children:[
      {
        path: 'details',
        name: 'SurveyDataDetails',
        component: () => import('../T4001SurveyData/public/PgSurveyData.vue'),
        // meta: { resetBreadcrumb: false }
      },
      {
        path: 'checkout',
        name: 'SurveyDataCheckout',
        component: () => import('../T4001SurveyData/public/PgSurveyDataCheckout.vue'),
        // meta: { resetBreadcrumb: false }
      },
      {
        path: '*',
        redirect: { name: 'SurveyDataDetails' }
      }
    ]
  },
  {
    portalGroup: 'advisory.public',
    path: '/survey/:seo',
    component: { render: h => h('router-view') },
    redirect: { name: 'SurveyDetails' },
    children:[
      {
        path: 'details',
        name: 'SurveyDetails',
        component: () => import('../T5001Survey/public/PgSurveyDetails.vue'),
        // meta: { resetBreadcrumb: false }
      },
      {
        path: 'start',
        name: 'SurveyStart',
        component: () => import('../T5001Survey/public/PgSurvey.vue'),
        // meta: { resetBreadcrumb: false }
      },
      {
        path: '*',
        redirect: { name: 'SurveyDetails' }
      }
    ]
  },
  // ---FIGMA_DESIGN---
  {
    // w/o Related Project
    portalGroup: 'advisory.public',
    path: '/tank/my',
    component: { render: h => h('router-view',{style:{maxWidth:'1440px'}}) },
    children:[
      // as a user, i want to see my dashboard
      // or choose to see my activities
      // or manage my content
      {
        path: '',
        name: 'AdvisorMyDashboard',
        // component: () => import('./PgTODO.vue'),
        // meta: { resetBreadcrumb: false }
      },
      {
        // As a Consumer/Customer, i can see the list of my activities
        path: 'activity',
        component: { render: h => h('router-view') },
        // meta: { resetBreadcrumb: false },
        children:[
          // CONFIRM 1: see multiple type in one page OR separate page? for list
          {
            path: 'list',
            name: 'PgT000MyActivityList',
            component: () => import('../T0000Base/customer/PgT0000MyCustomerList.vue'),
            meta: {
              title: '我的活動',
            }
          },
        ]
      },
      // TODO: bookmark
      {
        // As a Provider/Creator, i can manage my contents
        path: 'creator',
        name: 'PgT000MyCreator',
        component: { render: h => h('router-view') },
        redirect: {name:'PgT000MyCreatorList'},
        children:[
          {
            path: 'list',
            name: 'PgT000MyCreatorList', // 智庫列表
            component: () => import('../T0000Base/creator/PgT0000MyCreatorList.vue'),
            meta: {
              title: '智庫列表',
            }
          },
          // ? Job
          {
            path: 'job/:id?/edit',
            name: 'T1001CreatorEdit',
            component: () => import('../T1001JobPostingRequirement/creator/PgEdit.vue'),
          },
          {
            path: '/admin/job/:id?/review',
            name: 'T1001AdminReview',
            component: () => import('../T1001JobPostingRequirement/admin/PgReview.vue'),
            props: { isReview: true },
          },

          // ? Product + Service
          {
            path: 'service/:id?/edit',
            name: 'T2001CreatorEdit',
            component: () => import('../T2001Service/creator/PgEdit.vue'),
          },
          {
            path: '/admin/service/:id?/review',
            name: 'T2001AdminReview',
            component: () => import('../T2001Service/admin/PgReview.vue'),
            props: { isReview: true },
          },
          // ? FUNDING
          {
            path: 'funding/:id?/edit',
            name: 'T3001CreatorEdit',
            component: () => import('../T3001Funding/creator/PgEdit.vue'),
            children: [
              {
                // GOTO: public page
                path: 'preview',
                name: 'TBD:',
                redirect: { name: 'FundingDetails' }
              }
            ]
          },
          {
            path: '/admin/funding/:id?/review',
            name: 'T3001AdminReview',
            component: () => import('../T3001Funding/admin/PgReview.vue'),
            props: { isReview: true },
          },

          // ? surveydata
          {
            path: 'surveydata/:id?/edit',
            name: 'T4001CreatorEdit',
            component: () => import('../T4001SurveyData/creator/PgEdit.vue'),
          },
          {
            path: '/admin/surveydata/:id?/review',
            name: 'T4001AdminReview',
            component: () => import('../T4001SurveyData/admin/PgReview.vue'),
            props: { isReview: true },
          },
          // ? Survey
          {
            path: 'survey/:id?/edit',
            name: 'T5001CreatorEdit',
            component: () => import('../T5001Survey/creator/PgEdit.vue'),
          },
          {
            path: '/admin/survey/:id?/review',
            name: 'T5001AdminReview',
            component: () => import('../T5001Survey/admin/PgReview.vue'),
            props: { isReview: true },
          },
        ]
      },
      {
        path: '*',
        redirect: { name: 'PgT000MyActivityList' }
      }
    ]
  },
  {
    portalGroup: 'admin.admin',
    path: '/admin/content',
    component: { render: h => h('router-view',{style:{maxWidth:'1440px'}}) },
    children:[
      {
        // As a Admin, i can manage all the published contents
        path: 'published',
        name: 'AdminBrowseContent',
        component: { render: h => h('router-view') },
        redirect: {name:'AdminBrowseContentList'},
        children:[
          {
            path: 'list',
            name: 'AdminBrowseContentList', // 已發佈内容
            component: () => import('../T0000Base/creator/PgT0000AdminBrowseCreatorList.vue'),
            meta: {
              title: '已發佈内容',
            }
          },
          // ? Job
          {
            path: 'job/:id?/edit',
            name: 'T1001AdminBrowseCreatorEdit',
            component: () => import('../T1001JobPostingRequirement/creator/PgAdminBrowseEdit.vue'),
            meta: {
              title: '顧問',
            }
          },
          // ? Product + Service
          {
            path: 'service/:id?/edit',
            name: 'T2001AdminBrowseCreatorEdit',
            component: () => import('../T2001Service/creator/PgAdminBrowseEdit.vue'),
            meta: {
              title: '服務與產品',
            }
          },
          // ? FUNDING
          {
            path: 'funding/:id?/edit',
            name: 'T3001AdminBrowseCreatorEdit',
            component: () => import('../T3001Funding/creator/PgAdminBrowseEdit.vue'),
            meta: {
              title: '項目',
            },
          },
          // ? surveydata
          {
            path: 'surveydata/:id?/edit',
            name: 'T4001AdminBrowseCreatorEdit',
            component: () => import('../T4001SurveyData/creator/PgAdminBrowseEdit.vue'),
            meta: {
              title: '數據',
            }
          },
          // ? Survey
          {
            path: 'survey/:id?/edit',
            name: 'T5001AdminBrowseCreatorEdit',
            component: () => import('../T5001Survey/creator/PgAdminBrowseEdit.vue'),
            meta: {
              title: '市調',
            }
          },
        ]
      },
      {
        path: '*',
        redirect: { name: 'AdminBrowseContentList' }
      }
    ]
  },
]
