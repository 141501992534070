<template>
  <!-- <v-row
    v-debug -->
  <v-row
    v-if="baseDebug"
    justify="center"
  >
    <v-dialog
      v-model="dialog"
      v-bind="{
        //fullscreen: true,
      }"
      transition="dialog-bottom-transition"
      max-width="600px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Debug Dialog</v-toolbar-title>
          <v-spacer />
          <!-- <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              Save
            </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-list>
          <v-subheader>Session Info</v-subheader>
          <v-card>
            <v-card-subtitle>
              <div>Email: {{ user.email }} </div>
              <div>id: {{ user.id }} </div>
              <div>role_code: {{ user.role_code }} </div>
              <v-checkbox
                v-model="mockProduction"
                label="MockProduction (Refresh to take effect)"
                @change="toggleMockProduction"
              />
              <ChangeLanguageBtn />
            </v-card-subtitle>
          </v-card>
        </v-list>
        <v-divider />
        <v-list>
          <v-subheader>General</v-subheader>
          <v-text-field
            id="search"
            v-model="searchKey"
            outlined
            label="Search"
            class="mx-auto col-6"
          />
          <v-list-item
            v-for="(data,i) in filteredDatas"
            :key="data.title + i"
            :to="data.to"
            @click="onClick(data)"
          >
            <v-list-item-action>
              <v-icon>
                {{ data.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ data.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ data.desc }}</v-list-item-subtitle>
              <!-- <v-list-item-subtitle>{{ data.search }}</v-list-item-subtitle> -->
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { PortalUtils } from '@/utils/PortalUtils'
import ToggleMockProductionMixin from './ToggleMockProductionMixin'
import ChangeLanguageBtn from '../layouts/Header/ChangeLanguageBtn.vue';
import ApiUtils from '@/utils/api/ApiUtils';
export default {
  components: { ChangeLanguageBtn },
  mixins: [ToggleMockProductionMixin],
  data () {
    return {
      dialog: false,
      searchKey: '',
      datas: [],
      searchDatas: []
    }
  },
  computed: {
    filteredDatas () {
      return this.datas.filter((data) => {
        return data.search.includes(this.searchKey.toLowerCase())
      })
    }
  },
  watch: {
    dialog (val) {
      if (val) {
        this.$nextTick(() => {
          this.searchKey = ''
          document.getElementById('search').focus()
          console.log('focus')
        })
      }
    }
  },
  async created () {
    if(this.baseDebug) {
      // overwrite ctrl+p
      // window.addEventListener('keydown', this.keydownHandler, false)

      // generate user login
      const userList = await ApiUtils.postData({
        "data": [
          {
            "api": "E0000DemoService.getUserList"
          }
        ]
      }).then(
        (data) => {
          return data[0].output
        }
      )





      this.datas = this.generate([
        {
          type: 'route',
          title: 'AllCompsExample',
          to: { name: 'AllCompsExample', query: { portal: 'admin' } }
        },
        {
          icon: 'mdi-logout',
          desc: '(Debug Logout) Click to logout',
          title: 'Logout',
          onClick (that, input) {
            that.storeApiDispatch('Auth/logout')
              .then((data) => {
                that.$dialog.notify.info(that.$t('auth.logout.success'))
              })
          }
        },
        ...userList,
        ...['PROGRAM', 'COURSE', 'EVENT', 'SEMINAR'].map((item) => {
          return {
            icon: 'mdi-link',
            desc: 'Click to go to ContentDetails that has ' + item + ' type',
            title: 'Learn ' + item,
            onClick (that, input) {
              that.apiDispatch([{
                api: 'L0101ContentService.doRead_debug',
                input: item
              }]).then((data) => {
                that.$router.push({ name: 'ContentDetails', params: { seo: data[0].output.seo_url }, query: { portal: 'learn' } })
              })
            }
          }
        }),
        ...Object.keys(PortalUtils.portalMapping).map((item) => {
          return {
            icon: 'mdi-link',
            desc: 'Click to go to ' + item + ' portal',
            title: 'Go to ' + item + ' portal: `' + PortalUtils.portalMapping[item] + '`',
            onClick (that, input) {
              that.$router.push({ name: 'Landing', query: { portal: item } })
            }
          }
        }),

        // BUG
        ...this.$router.getRoutes().map((item) => {
          if (item.name === null || item.name === undefined) { return null }
          return {
            icon: 'mdi-link',
            desc: item.path + '(dynamic route may not work)',
            title: '[Auto Gen Route]' + item.name,
            onClick (that, input) {
              that.$router.push({ path: item.path })
            }
          }
        })
      ])
    }
  },
  methods: {
    keydownHandler (e) {
      if (e.keyCode === 80 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
        e.preventDefault()

        this.dialog = !this.dialog
      }
      // if (e.ctrlKey && e.key === 'm') {
      //   this.mockProduction = !this.mockProduction
      //   this.$dialog.notify.info('MockProduction: ' + this.mockProduction)
      // }
    },
    generate (data) {
      // filter out null
      data = data.filter((item) => {
        return item !== null
      })
      return data.map((item) => {
        const { type, ...rest } = item
        const map = {
          route: {
            icon: 'mdi-link',
            desc: 'Click to go to route'
          },
          login: {
            icon: 'mdi-login',
            desc: '(Debug Login)Click to login as ',
            onClick (that, input) {
              that.storeApiDispatch('Auth/login', input)
                .then((data) => {
                  that.$dialog.notify.success(that.$t('auth.login.success') + input.email)
                  window.location.reload()
                })
            }
          }
        }
        const res = {
          ...map[type],
          ...rest
        }
        return {
          ...res, search: JSON.stringify(res).toLowerCase()
        }
      })
    },
    onClick (data) {
      if (data.onClick) {
        data.onClick(this, data.input)
      }
    }
  }
}
</script>
