// import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'
import ApiUtils from '@/utils/api/ApiUtils'
import i18n from '@/plugins/i18n'

// TODO: NEED TIN CONFIMATION
// TODO: update `text` zh_tw
const statusMap={
  DRAFT: {
    text: '草稿',
    color: 'grey',
    topBtnList: ['moreSetting','preview', 'submitForApproval'],
    editBtnList: ['preview', 'submitForApproval','doSave'],
    canEdit: true, // got `doSave` button == canEdit,
    showTabsList: ['edit'],
  },
  PENDING: {
    text: '待審批',
    color: 'orange',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showReviewNotification: true,
    showTabsList: ['edit'],
  },
  AMENDMENT: {
    text: '要求修正',
    color: 'orange',
    topBtnList: ['moreSetting','preview', 'submitForApproval'],
    editBtnList: ['preview', 'submitForApproval','doSave'],
    showReviewNotification: true,
    canEdit: true,
    showTabsList: ['edit'],
  },
  // BUG: NO APPROVED Status, maybe similar to `UNPUBLISHED` because after admin approved,
  // it will be `UNPUBLISHED` if creator not choose to publish when admin approved
  REJECTED: {
    text: '審批未通過',
    color: 'red',
    topBtnList: ['moreSetting','preview','submitForApproval'],
    editBtnList: ['preview','submitForApproval'],
    showReviewNotification: true,
    showTabsList: ['edit'],
  },
  PUBLISHED: {
    text: '已發佈',
    color: 'green',
    topBtnList: ['moreSetting','preview','doSave'],
    editBtnList: ['download','preview','doSave'],
    showReviewNotification: true,
    canEdit: true, // ['title']
    showTabsList: ['edit','comments','export'],
  },
  UNPUBLISHED: {
    text: '未發佈',
    color: 'grey',
    topBtnList: ['moreSetting','preview','publish','doSave'],
    editBtnList: ['preview','publish','doSave'],
    showReviewNotification: true,
    canEdit: true,
    showTabsList: ['edit'],
  },
  DELETED: {
    text: '已刪除',
    color: 'red',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showTabsList: ['edit'],
  },
  REMOVED: {
    text: '已被移除',
    color: 'red',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showTabsList: ['edit'],
  }
}

const typeMap={
  INTERNAL:{
    text: i18n.t('t5001.publicView.internal'),
    color: 'grey',
  },
  EXTERNAL:{
    text: i18n.t('t5001.publicView.external'),
    color: 'grey',
  }
}

function getMapArray(obj){
  return Object.keys(obj).map(key => {
    return {
      value: key,
      ...obj[key],
    }
  })
}


const PageConst= {
  FORM_DEF: {
    // if cannot use dont use
    // editDef: [
    //   { name: 'name', label: 'Name', type: 'text', required: true },
    //   { name: 'description', label: 'Description', type: 'textarea', required: false }
    // ]
  },
  SEARCH_DEF: {
    inputDef: [
      {
        column: 'col-8',
        data: 'survey_title',
        type: 'text',
        label: '標題',
        props: {
          clearable: true
        }
      },
      {
        column: 'col-4',
        data: 'survey_status',
        type: 'autoComplete',
        label: '狀態',
        props: {
          items: getMapArray(statusMap),
          itemText: 'text',
          itemValue: 'value',
          clearable: true
        }
      },
    ],
    sortDef:[
      {
        text: '最近更新',
        value: {order_updated_at: 'desc'}
      },
      {
        text: '最早更新',
        value: {order_updated_at: 'asc'}
      },
    ],
  },
  LIST_DEF: {
    // tableDef: [
    // { name: 'name', label: 'Name', align: 'left', sortable: true },
    // { name: 'description', label: 'Description', align: 'left', sortable: false }
    // ]
  },
  REVIEW_DEF:{
    rel_model: 'T5001Survey',
    key: 'tank.survey',
  },
  ACTION: {
    doAdd: { api: 'T5001SurveyService.doAdd_creator'},
    getEdit: { api: 'T5001SurveyService.getEdit_creator'},
    doEdit: { api: 'T5001SurveyService.doEdit_creator'},
    doRead: { api: 'T5001SurveyService.doRead_creator'},
    doDelete: { api: 'T5001SurveyService.doDelete_creator'},
    doBrowse: { api: 'T5001SurveyService.doBrowse_creator'},
    requestApproval: { api: 'T5001SurveyService.requestApproval'}
  },
}

const module = {
  state: {
    PageConst,
    statusMap,
    statusMapArray: getMapArray(statusMap),

    typeMap,
    typeMapArray: getMapArray(typeMap),
    // set default here
    searchInfo:{
    },
    // set default here
    orderBy:PageConst.SEARCH_DEF.sortDef[0].value,
    totalsurvey: 0
  },

  getters: {

  },

  mutations: {

  },
  actions: {
    async requestApproval ({ commit,state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: state.PageConst.ACTION.requestApproval.api,
          input: state.row
        }] })
      return res[0].output
    },
    async getTotalSurvey ({commit,state}, payload) {
      const res = await ApiUtils.postData({
        data: [{api: 'T5101SurveyAnswerService.getTotalSurvey', input: payload}]
      })
      state.totalsurvey = res[0].output[0].total

      return res[0].output
    },
    async getCSVDownloadLink ({commit,state},payload) {
      const res = await ApiUtils.postData({
        data: [{api: 'T5001SurveyService.getCSVDownloadLink', input: {id: state.row.id}}]
      })
      return res[0].output
    },
    async getJson({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: 'T5001SurveyService.getJson', input: { id: state.row.id } }]
      })
      return res[0].output
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
